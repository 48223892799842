import React, { FC, useCallback, useMemo, useState } from 'react';
import { Col, Form, Input, InputNumber, Row, Upload, UploadFile } from 'antd';
import Buttons from 'components/Global/Buttons';
import { formatToDateOnly, invalidText, toastText } from 'utils/utils';
import './index.scss';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import DatePickerField from 'components/Global/DatePicker';
import dayjs from 'dayjs';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { payeApi } from 'Api/payeApi';
import { UploadChangeParam } from 'antd/es/upload';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchCompanyWizard } from '../../../../../../redux/actions/payrollSettingWizardAction';
interface EmployeeShareTier {
	sortId: number;
	minAmount: number | null;
	maxAmount: number | null;
	percentage: number | null;
	id: number;
}

const AddPayeSideDrawer: FC<SideDrawerBodyProps> = (props) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const [date, setDate] = useState<any>(dayjs());
	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [editableRowIndex, setEditableRowIndex] = useState<number>(0);

	const dispatch = useDispatch<AppDispatch>();

	const initialEmployeeShare: EmployeeShareTier[] = useMemo(
		() => [
			{
				id: 1,
				sortId: 0,
				minAmount: null,
				maxAmount: null,
				percentage: null,
			},
			{
				id: 2,
				sortId: 1,
				minAmount: null,
				maxAmount: null,
				percentage: null,
			},
			{
				id: 3,
				sortId: 2,
				minAmount: null,
				maxAmount: null,
				percentage: null,
			},
			{
				id: 4,
				sortId: 3,
				minAmount: null,
				maxAmount: Infinity,
				percentage: null,
			},
		],
		[]
	);

	const { closeDrawerByAnimation, fetchLatestPayeData, fetchAllPayeDetails } =
		props;

	const handleDate = useCallback((value: any) => {
		setDate(value);
	}, []);

	const updateEmployeeShare = useCallback(
		(
			index: number,
			field: 'minAmount' | 'maxAmount' | 'percentage',
			value: number | null
		) => {
			const employeeShare = form.getFieldValue('employeeShare');
			const updatedShare = [...employeeShare];

			updatedShare[index][field] = value;

			if (field === 'maxAmount' && value !== null) {
				if (index < updatedShare.length - 1) {
				}
				setEditableRowIndex(index + 1);

				updatedShare[index + 1].minAmount = value + 1;
			}
			form.setFieldsValue({ employeeShare: updatedShare });
		},
		[form]
	);

	const handleSubmit = async (values: any) => {
		setLoading(true);

		try {
			const payeFormData = new FormData();

			payeFormData.append(
				'effectiveDate',
				formatToDateOnly(values.effectiveDate)
			);
			payeFormData.append(
				'employeeShare',
				JSON.stringify(values.employeeShare)
			);
			payeFormData.append(
				'notes',
				invalidText(values.notes) ? null : values.notes
			);
			payeFormData.append('moduleName', 'PAYE');
			payeFormData.append('file', file);

			await payeApi.createPaye(payeFormData);
			dispatch(fetchCompanyWizard());
			toastText('PAYE details saved successfully', 'success');
			closeDrawerByAnimation(true);
			fetchLatestPayeData();
			fetchAllPayeDetails();
		} catch (error) {
			toastText('error', 'Failed to save PAYE details');
		} finally {
			setLoading(false);
		}
	};

	const buttons = useMemo(
		() => [
			{
				text: 'Cancel',
				isLoading: false,
				className: 'btn-cancel',
				isSubmit: false,
				disabled: loading,
				fontSize: '1.8rem',
				minWidth: '60px',
				minHeight: '42px',
				onclick: () => {
					closeDrawerByAnimation();
				},
			},
			{
				text: 'Save',
				isLoading: loading,
				className: 'btn-blue',
				isSubmit: true,
				fontSize: '1.8rem',
				minWidth: '100px',
				minHeight: '42px',
				disabled: false,
				onclick: () => {},
				font: '16px',
			},
		],
		[loading, closeDrawerByAnimation]
	);

	const propsUpload = useMemo(
		() => ({
			name: 'file',
			//  accept: '.xls,.xlsx,.csv',
			maxCount: 1,
			fileList: fileList,
			beforeUpload: (file: UploadFile) => {
				if (!file) return;

				// const isValidType = [
				//   'application/vnd.ms-excel',
				//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				//   'text/csv',
				//   'application/csv'
				// ].includes(file.type!);

				// if (!isValidType) {
				//   toastText('Invalid file type! Only Excel files (.xls, .xlsx) and CSV files (.csv) are allowed.', 'error');
				//   return Upload.LIST_IGNORE;
				// }

				const isLt1M = file.size! / 1024 / 1024 < 5;
				if (!isLt1M) {
					toastText('File must be smaller than 5MB!', 'error');
					return Upload.LIST_IGNORE;
				}

				setFile(file);
				setFileList([file]);
				return false;
			},
			onChange(info: UploadChangeParam<UploadFile>) {
				const { file } = info;
				if (file.status !== 'removed') {
					setFile(file);
					setFileList([file]);
				} else {
					setFile(null);
					setFileList([]);
				}
			},
			onDrop: () => {
				setFile(null);
				setFileList([]);
			},
			onRemove: () => {
				setFile(null);
				setFileList([]);
			},
		}),
		[fileList]
	);

	return (
		<div className={styles['side-drawer-body']}>
			<Form
				form={form}
				name="basic"
				className={styles['side-drawer-form']}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 24 }}
				style={{ maxWidth: 600 }}
				onFinish={handleSubmit}
				initialValues={{
					effectiveDate: date,
					employeeShare: initialEmployeeShare,
				}}
				autoComplete="off"
			>
				<p className={styles['form-container-head-warning']}>
					<b>
						{' '}
						<sup>*</sup>
					</b>{' '}
					Indicated mandatory fields
				</p>
				<div className={styles['side-drawer-form__inputs']}>
					<label className={styles['side-drawer-form__paye--label']}>
						{'Effective Date'}{' '}
						<span className="required-color">*</span>
					</label>
					<div>
						<Form.Item
							name="effectiveDate"
							rules={[
								{
									required: true,
									message: 'Please select a date',
								},
							]}
						>
							<DatePickerField
								onChange={handleDate}
								value={date}
								isError={false}
								disabled={false}
								placeholder="Select Date"
								required
								name="effectiveDate"
								disabledBeforeDates={dayjs().startOf('day')}
							/>
						</Form.Item>
					</div>
				</div>

				<div className={styles['side-drawer-form__inputs']}>
					<label className={styles['side-drawer-form__paye--label']}>
						{'Employee share'}{' '}
						<span className="required-color">*</span>
					</label>
					<div className={styles['employee-share-table']}>
						<div className={styles['employee-share-header']}>
							<span>Min amount</span>
							<span>Max amount</span>
							<span>Percentage</span>
						</div>
						<Form.List name="employeeShare">
							{(fields) => (
								<div key="employeeShareWrapper">
									{fields.map((field, index) => {
										const tier = form.getFieldValue([
											'employeeShare',
											index,
										]);
										return (
											<div
												className={
													styles['employee-share-row']
												}
												key={tier.id}
											>
												<Form.Item
													className="employee-share-input"
													{...field}
													key={`${tier.id}-minAmount`}
													name={[
														field.name,
														'minAmount',
													]}
													rules={[
														{
															validator: (
																_,
																value
															) => {
																const employeeShare =
																	form.getFieldValue(
																		'employeeShare'
																	);

																if (
																	value ===
																		null ||
																	value <= 0
																) {
																	if (
																		index ===
																		0
																	) {
																		return Promise.resolve();
																	}
																	return Promise.reject(
																		new Error(
																			'Min amount is not valid'
																		)
																	);
																}

																if (
																	index > 0 &&
																	value <=
																		employeeShare[
																			index -
																				1
																		]
																			.maxAmount
																) {
																	return Promise.reject(
																		new Error(
																			'Min amount is not valid'
																		)
																	);
																}

																return Promise.resolve();
															},
														},
													]}
												>
													<InputNumber
														placeholder="Min amount"
														addonAfter="ZMW"
														onChange={(
															value: any
														) =>
															updateEmployeeShare(
																index,
																'minAmount',
																value
															)
														}
														disabled={
															index >
															editableRowIndex
														}
													/>
												</Form.Item>

												<Form.Item
													className="employee-share-input"
													{...field}
													key={`${tier.id}-maxAmount`}
													name={[
														field.name,
														'maxAmount',
													]}
													rules={[
														{
															validator: (
																_,
																value
															) => {
																const employeeShare =
																	form.getFieldValue(
																		'employeeShare'
																	);

																if (
																	value ===
																		null ||
																	value <= 0
																) {
																	return Promise.reject(
																		new Error(
																			'Max amount is not valid'
																		)
																	);
																}

																if (
																	index <
																		employeeShare.length -
																			1 &&
																	value <=
																		employeeShare[
																			index
																		]
																			.minAmount
																) {
																	return Promise.reject(
																		new Error(
																			'Max amount is not valid'
																		)
																	);
																}

																return Promise.resolve();
															},
														},
													]}
												>
													<InputNumber
														placeholder="Max amount"
														addonAfter="ZMW"
														onChange={(
															value: any
														) =>
															updateEmployeeShare(
																index,
																'maxAmount',
																value
															)
														}
														disabled={
															index >
																editableRowIndex ||
															index === 3
														}
													/>
												</Form.Item>

												<Form.Item
													className="employee-share-input"
													{...field}
													key={`${tier.id}-percentage`}
													name={[
														field.name,
														'percentage',
													]}
													rules={[
														{
															validator: (
																_,
																value
															) => {
																if (
																	value ===
																		null ||
																	value <=
																		0 ||
																	value >= 100
																) {
																	if (
																		index ===
																		0
																	) {
																		return Promise.resolve();
																	}
																	return Promise.reject(
																		new Error(
																			'Percentage is not valid'
																		)
																	);
																}
																return Promise.resolve();
															},
														},
													]}
												>
													<InputNumber
														placeholder="Enter Percentage"
														addonAfter="%"
														onChange={(
															value: any
														) =>
															updateEmployeeShare(
																index,
																'percentage',
																value
															)
														}
														disabled={
															index >
															editableRowIndex
														}
													/>
												</Form.Item>
											</div>
										);
									})}
								</div>
							)}
						</Form.List>
					</div>
				</div>

				<div className={styles['side-drawer-form__inputs']}>
					<label className={styles['side-drawer-form__napsa--label']}>
						{'Attachment'}
					</label>
					<Form.Item name="attachment">
						<Dragger {...propsUpload} className="test">
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-hint">
								Drag and drop a file or choose file from
								{/* Device Supported file formats. */}
								<br />
								{/* XLS, XLSX, CSV |{' '} */}
								<span className="color-purple">
									Maximum file size: 5MB
								</span>
							</p>
						</Dragger>
					</Form.Item>
				</div>
				<div className={styles['side-drawer-form__inputs']}>
					<label className={styles['side-drawer-form__paye--label']}>
						{'Notes'}
					</label>
					<div>
						<Form.Item name="notes">
							<Input.TextArea
								placeholder="Enter notes"
								className={styles['side-drawer-form--textarea']}
							/>
						</Form.Item>
					</div>
				</div>

				<div className={styles['side-drawer-form__buttons']}>
					<Form.Item>
						<Buttons buttons={buttons} />
					</Form.Item>
				</div>
			</Form>
		</div>
	);
};

export default AddPayeSideDrawer;
