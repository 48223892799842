import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Space, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { EmployeeTableInterface } from 'interfaces/employee.interface';
import { useSearchParams } from 'react-router-dom';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';
import {
	ActiveStatusSvg,
	DeceasedSvg,
	DismissedSvg,
	ReassignedSvg,
	RedundantSvg,
	RetiredSvg,
	SuspendedSvg,
} from 'utils/svgs';

const { Column } = Table;

type Props = {
	employeeData: EmployeeTableInterface[];
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	isLoading: boolean;
	setIsAddEmployeeModalOpen: (value: boolean) => void;
	setViewOnlyEmployeeDetails: (value: boolean) => void;
	showModal: any;
	setDeleteEmployee: any;
};

const EmployeeTable = (props: Props) => {
	const {
		employeeData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		setIsAddEmployeeModalOpen,
		setViewOnlyEmployeeDetails,
		showModal,
		setDeleteEmployee,
	} = props;
	const [searchParams, setSearchParams] = useSearchParams();

	const permissionContext = useContext(PermissionContext);

	const handleEditEmployee = (id: string) => {
		setIsAddEmployeeModalOpen(true);
		setSearchParams({
			employeeId: id,
		});
	};

	// Delete user data handler
	const deleteDataHandler = (id: any) => {
		setDeleteEmployee(id);
		// setSearchParams({
		// 	employeeId: id,
		// });
		showModal();
	};

	const handleViewEmployee = (id: string) => {
		setViewOnlyEmployeeDetails(true);
		setSearchParams({
			employeeId: id,
			onlyView: 'true',
		});
	};

	const isEditEmployment =
		permissionContext.allowedPermissions.includes('Edit_Employment');
	const isEditSalary =
		permissionContext.allowedPermissions.includes('Edit_Salary');
	const isEditPaymentInfo =
		permissionContext.allowedPermissions.includes('Edit_Payment_Info');
	const isEditDocuments =
		permissionContext.allowedPermissions.includes('Edit_Documents');
	const isEditDeductions =
		permissionContext.allowedPermissions.includes('Edit_Deductions');
	const isEditEarnings =
		permissionContext.allowedPermissions.includes('Edit_Earnings');

	const isEdit =
		isEditEmployment ||
		isEditSalary ||
		isEditPaymentInfo ||
		isEditDocuments ||
		isEditDeductions ||
		isEditEarnings;

	const isDeleteEmployment =
		permissionContext.allowedPermissions.includes('Delete_Employment');
	const isDeleteSalary =
		permissionContext.allowedPermissions.includes('Delete_Salary');
	const isDeletePaymentInfo = permissionContext.allowedPermissions.includes(
		'Delete_Payment_Info'
	);
	const isDeleteDocuments =
		permissionContext.allowedPermissions.includes('Delete_Documents');
	const isDeleteDeductions =
		permissionContext.allowedPermissions.includes('Delete_Deductions');
	const isDeleteEarnings =
		permissionContext.allowedPermissions.includes('Delete_Earnings');

	const isDelete =
		isDeleteEmployment ||
		isDeleteSalary ||
		isDeletePaymentInfo ||
		isDeleteDocuments ||
		isDeleteDeductions ||
		isDeleteEarnings;

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={employeeData}
				// scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Employee Name"
					dataIndex="firstName"
					key="firstName"
					className="bg-white"
					render={(text, record: any) => (
						<span
							style={{ cursor: 'pointer', color: 'blue' }}
							onClick={() => handleViewEmployee(record.id)}
						>
							{`${record.firstName} ${record.lastName}`}
						</span>
					)}
					sorter={true}
				/>
				<Column
					title="Employee Code"
					dataIndex="employeeId"
					key="employeeId"
					className="bg-white"
					sorter={true}
				/>
				<Column
					title="Joining Date"
					dataIndex="joiningDate"
					key="joiningDate"
					className="bg-white"
					render={(value: string) =>
						dayjs(value).format('DD/MM/YYYY')
					}
					sorter={true}
				/>
				<Column
					title="Currency"
					dataIndex="currency"
					className="bg-white"
					key="currency"
					render={(value: any, record: any) => {
						return record.isUSD ? 'USD' : 'ZMW';
					}}
				/>
				<Column
					title="Gender"
					dataIndex="gender"
					className="bg-white"
					key="gender"
					sorter={true}
				/>

				<Column
					title="Status"
					className="bg-white"
					dataIndex="recordStatus"
					key="recordStatus"
					render={(value) => {
						let statusText = '';
						let statusClass = '';
						let StatusIcon = null;
						switch (value) {
							case 'ACTIVE':
								statusText = 'Active';
								statusClass =
									styles['status-active'];
								StatusIcon = <ActiveStatusSvg />;
								break;
							//	return <p className="green">Active</p>;
							case 'SUSPENDED':
								statusText = 'Suspended';
								statusClass =
									styles['status-suspended'];
								StatusIcon = <SuspendedSvg />;
								break;
							//	return <p className="red">Suspended</p>;
							case 'RETIRED':
								statusText = 'Retired';
								statusClass =
									styles['status-retired'];
								StatusIcon = <RetiredSvg />;
								break;
							//	return <p className="green">Retired</p>;
							case 'REASSIGNED':
								statusText = 'Reassigned';
								statusClass =
									styles['status-reassigned'];
								StatusIcon = <ReassignedSvg />;
								break;
							//		return <p className="green">Reassigned</p>;
							case 'DISMISSED':
								statusText = 'Dismissed';
								statusClass =
									styles['status-dismissed'];
								StatusIcon = <DismissedSvg />;
								break;
							//		return <p className="red">Dismissed</p>;
							case 'DECEASED':
								statusText = 'Deceased';
								statusClass =
									styles['status-deceased'];
								StatusIcon = <DeceasedSvg />;
								break;
							//	return <p className="green">Deceased</p>;
							case 'REDUNDANT':
								statusText = 'Redundant';
								statusClass =
									styles['status-redundant'];
								StatusIcon = <RedundantSvg />;
								break;
							//	return <p className="green">Redundant</p>;
							default:
								statusText = 'Unknown Status';
								statusClass = styles['status-unknown'];
								StatusIcon = null;
							//			return <p>{value}</p>;
						}
						return (
							<Tag
								bordered={false}
								className={`${styles['statusTag']} ${statusClass}`}
							>
								<span className={styles['statusIcon']}>
									{StatusIcon}
								</span>
								<span>{statusText}</span>
							</Tag>
						);
					}}
					sorter={true}
				/>
				{/* <Column
					title="Status"
					dataIndex="recordStatus"
					key="recordStatus"
					render={(value) =>
						value ? (
							<p className="green">Active</p>
						) : (
							<p className="red">Suspended</p>
						)
					}
					sorter={true}
				/> */}
				{(isEdit || isDelete) && (
					<Column
						title="Action"
						className="bg-white"
						key="action"
						render={(_: any, record: any) => (
							<Space size="middle">
								{isEdit && (
									<p className="cursor-pointer">
										<EditOutlined
											style={{
												fontSize: 18,
											}}
											onClick={() => {
												handleEditEmployee(record.id);
											}}
										/>
									</p>
								)}
								{isDelete && (
									<p className="cursor-pointer">
										<DeleteOutlined
											style={{
												fontSize: 18,
												cursor: 'pointer',
											}}
											onClick={() =>
												deleteDataHandler(record.id)
											}
										/>
									</p>
								)}
							</Space>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default EmployeeTable;
