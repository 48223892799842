import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import Buttons from 'components/Global/Buttons';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { leaveRequestApi } from 'Api/leaveRequest';
import Ellipse from 'components/Global/Ellipse';

const { Title, Text } = Typography;

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedLeaveRequest: string;
	selectedLeaveRequestLabel: string;
	approvalId: string;
	fetchApprovalRequest: () => void;
};

const NotificationLeaveRequestModal: React.FC<Props> = ({
	isOpen,
	handleCancel,
	selectedLeaveRequest,
	selectedLeaveRequestLabel,
	approvalId,
	fetchApprovalRequest,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [approveIsLoading, setApproveIsLoading] = useState(false);
	const [rejectIsLoading, setRejectIsLoading] = useState(false);
	const [LeaveDetails, setLeaveDetails] = useState<any>(null);

	const fetchLeaveRequest = async (id: string) => {
		try {
			setIsLoading(true);
			const response = await leaveRequestApi.getLeaveRequestById(id);
			setLeaveDetails(response.data.data);
		} catch (err) {
			toastText('Something went wrong in getting Leave request', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	async function approveLeaveRequest() {
		try {
			setApproveIsLoading(true);
			await leaveRequestApi.approveLeave({
				approvalId,
				leaveRequestId: selectedLeaveRequest,
			});
		} catch (error) {
			toastText(
				'Something went wrong in approving Leave request',
				'error'
			);
		} finally {
			setApproveIsLoading(false);
			toastText('Leave approved successfully', 'success');
			handleCancel();
			fetchApprovalRequest();
		}
	}

	async function rejectLeaveRequest() {
		try {
			setRejectIsLoading(true);
			await leaveRequestApi.rejectLeave({
				approvalId,
				leaveRequestId: selectedLeaveRequest,
			});
		} catch (error) {
			toastText(
				'Something went wrong in rejecting Leave request',
				'error'
			);
		} finally {
			setRejectIsLoading(false);
			toastText('Leave rejected successfully', 'success');
			handleCancel();
			fetchApprovalRequest();
		}
	}

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			isSubmit: true,
			className: 'secondary-button',
			onclick: handleCancel,
			disabled: isLoading,
		},
		{
			text: 'Approve',
			isSubmit: true,
			className: 'btn-blue',
			onclick: approveLeaveRequest,
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			size: 'small',
			isLoading: approveIsLoading,
		},
		{
			text: 'Reject',
			isSubmit: true,
			className: 'secondary-button',
			onclick: rejectLeaveRequest,
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			isLoading: rejectIsLoading,
		},
	];

	useEffect(() => {
		if (selectedLeaveRequest) {
			fetchLeaveRequest(selectedLeaveRequest);
		}
	}, [selectedLeaveRequest]);

	return (
		<Modal
			open={isOpen}
			className="notificationLeaveRequest"
			closable={false}
			onCancel={handleCancel}
			width={900}
			footer={() => (
				<div className={styles.footerButtons}>
					<Buttons buttons={buttons} />
				</div>
			)}
		>
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '40vh',
					}}
				>
					<LoadingOutlined
						style={{ fontSize: '100px', color: '#584495' }}
					/>
				</div>
			) : LeaveDetails ? (
				<div className={styles.container}>
					<Title level={3}>{selectedLeaveRequestLabel}</Title>
					<div className={styles.infoSection}>
						<div className={styles.infoItem}>
							<p>Employee</p>
							<p>{`${LeaveDetails.employee.firstName} ${LeaveDetails.employee.lastName} (${LeaveDetails.employee.employeeId})`}</p>
						</div>
						<div className={styles.infoItem}>
							<p>Leave Type</p>
							<p>{`${LeaveDetails?.leaveName}`}</p>
						</div>
						<div className={styles.infoItem}>
							<p>Start Date</p>
							<p>
								{dayjs(LeaveDetails.startDate).format(
									'DD-MM-YYYY'
								)}
							</p>
						</div>
						<div className={styles.infoItem}>
							<p>End Date</p>
							<p>
								{dayjs(LeaveDetails.endDate).format(
									'DD-MM-YYYY'
								)}
							</p>
						</div>
						<div className={styles.infoItem}>
							<p>No. of Leave Days</p>
							<p>{LeaveDetails.noOfDays}</p>
						</div>
						{LeaveDetails.documentUrl ? (
							<div className={styles.infoItem}>
								<p>Leave Attachment</p>
								<p>
									<a
										href={LeaveDetails?.documentLink}
										target="_blank"
										rel="noopener noreferrer"
									>
										<Ellipse
											message={LeaveDetails?.documentName}
											maxLength={50}
											key={LeaveDetails?.documentName}
											tooltipMessage={
												LeaveDetails?.documentName
											}
											isTooltip={true}
										/>
									</a>
								</p>
							</div>
						) : null}

						<div className={styles.infoItem}>
							<p>Reason</p>

							<Ellipse
								message={LeaveDetails.reason}
								maxLength={50}
								key={LeaveDetails.reason}
								tooltipMessage={LeaveDetails.reason}
								isTooltip={true}
							/>
						</div>
					</div>
				</div>
			) : (
				<div>No leave details available</div>
			)}
		</Modal>
	);
};

export default NotificationLeaveRequestModal;
