import { Button, Divider, Popover } from 'antd';
import { CommunicationSvg, InformationIcon } from 'utils/svgs';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

type headerProps = {
	openCalculateModal: () => void;
	btnText: string;
	openInformationModal: () => void;
	openAddCommunicationModal: () => void;
	extraDetailsPopover: boolean;
	toggleExtraDetails: () => void;
	showExtraDetails?: boolean;
};

const GlobalStatutoryHeader = (props: headerProps) => {
	console.log('loaded');
	
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	const {
		openCalculateModal,
		openInformationModal,
		btnText,
		openAddCommunicationModal,
		extraDetailsPopover,
		toggleExtraDetails,
		showExtraDetails = true,
	} = props;
	
	console.log('openInformationModal: ', openInformationModal);
	const popOverChange = () => {
		toggleExtraDetails();
	};

	const PopOverDetails = () => {
		if (!openInformationModal || !openAddCommunicationModal) return null;
		return (
			<div className={styles['pop-over-details']}>
				<div
					className={styles['pop-over-details--content']}
					onClick={openInformationModal}
				>
					<InformationIcon />
					<p>Information</p>
				</div>
				<Divider className={styles['pop-over-details--divider']} />
				<div
					className={styles['pop-over-details--content']}
					onClick={openAddCommunicationModal}
				>
					<CommunicationSvg />
					<p>Add Communication</p>
				</div>
			</div>
		);
	};

	return (
		<>
			{isAdd && (
				<div className={styles['header']}>
					<p> </p>
					<div className={styles['buttons']}>
						<Button
							className="btn-blue"
							onClick={openCalculateModal}
							loading={false}
						>
							{`Calculate ${btnText}`}
						</Button>
						{showExtraDetails && (
							<Popover
								placement="bottomRight"
								content={<PopOverDetails />}
								trigger="click"
								open={extraDetailsPopover}
								onOpenChange={popOverChange}
							>
								<Button
									className={styles['secondary-btn']}
									onClick={toggleExtraDetails}
								>
									...
								</Button>
							</Popover>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default GlobalStatutoryHeader;
