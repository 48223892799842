import { Table } from 'antd';
import styles from './index.module.scss';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';

const { Column } = Table;

type Props = {
	ReportData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
};

const GlobalReportTable = (props: Props) => {
	const {
		ReportData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={ReportData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period Name"
					dataIndex="payPeriod"
					key="payPeriod"
					className="bg-white"
					render={(text) => text?.name}
				/>
				<Column
					title="Generated Date"
					dataIndex="createdAt"
					key="createdAt"
					sorter={true}
					className="bg-white"
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
				/>
				<Column
					title="Generated By"
					dataIndex="createdBy"
					key="createdBy"
					className="bg-white"
					render={(text) => text?.fullName}
				/>
				<Column
					title="No. of Employees"
					dataIndex="noOfEmployees"
					key="noOfEmployees"
					sorter={true}
					className="bg-white"
				/>
				<Column
					title="Report"
					dataIndex="documentLink"
					key="documentLink"
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record.documentUrl ? (
								<a
									href={record.documentLink}
									target="_blank"
									rel="noopener noreferrer"
									style={{ cursor: 'pointer' 	,	opacity: 1}}
								>
									<Ellipse
										message={record.documentName}
										maxLength={20}
										key={record.documentName}
										tooltipMessage={record.documentName}
										isTooltip={true}
														isLink={true}
									/>
								</a>
							) : (
								'-'
							)}
						</div>
					)}
				/>
			</Table>
		</div>
	);
};

export default GlobalReportTable;
