import {
	Col,
	Form,
	Input,
	InputNumber,
	Row,
	UploadFile,
	Upload,
	Select,
	Button,
} from 'antd';
import { FC, useState } from 'react';
import Buttons from 'components/Global/Buttons';
import { formatToDateOnly, invalidText, toastText } from 'utils/utils';
import './index.scss';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import DatePickerField from 'components/Global/DatePicker';
import dayjs from 'dayjs';
import Dragger from 'antd/es/upload/Dragger';
import {
	InboxOutlined,
	MinusCircleOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { UploadChangeParam } from 'antd/es/upload';
import { personalLevyApi } from 'Api/personalLevy';
type Entry = {
	month: string | null;
	amount: number | null;
	day: number | null;
	[key: string]: any;
};
const AddPersonalLevySideDrawer: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [date, setDate] = useState<any>(dayjs());
	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const [entries, setEntries] = useState<Entry[]>([
		{ month: null, amount: null, day: null },
	]);

	let isRemoving = false;

	const [form] = Form.useForm();

	const {
		closeDrawerByAnimation,
		fetchPersonalLevyData,
		fetchAllPersonalLevyData,
	} = props;
	const onFormChange = () => {
		const values = form.getFieldsValue();
		setEntries(values.entries || []);
	};

	const handleChange = (index: number, field: keyof Entry, value: any) => {
		const newEntries = [...entries];
		newEntries[index][field] = value;
		setEntries(newEntries);

		form.setFieldsValue({
			entries: newEntries,
		});
	};

	const handleDate = (value: any) => {
		setDate(value);
	};
	const addNewRow = () => {
		const newEntries = [
			...entries,
			{ month: null, amount: null, day: null },
		];
		setEntries(newEntries);
		form.setFieldsValue({ entries: newEntries });
	};

	const removeEntry = (index: number) => {
		if (index >= 0 && index < entries.length) {
			const updatedEntries = entries.filter((_, i) => i !== index);
			setEntries(updatedEntries);
			form.setFieldsValue({ entries: updatedEntries });
		}
	};
	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
			disabled: loading,
			onclick: () => {
				closeDrawerByAnimation();
			},
		},
		{
			text: 'Save',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			fontSize: '1.8rem',
			minWidth: '100px',
			minHeight: '42px',
			onclick: () => {},
			font: '16px',
		},
	];

	const propsUpload = {
		name: 'file',
		// accept: '.xls,.xlsx,.csv',
		maxCount: 1,
		fileList: fileList,
		beforeUpload: (file: UploadFile) => {
			if (!file) {
				return;
			}
			const isLt1M = file.size! / 1024 / 1024 < 5;
			if (!isLt1M) {
				toastText('File must be smaller than 5MB!', 'error');
				return Upload.LIST_IGNORE;
			}

			setFile(file);
			setFileList([file]);
			return false; // Prevents automatic upload
		},
		onChange(info: UploadChangeParam<UploadFile>) {
			const { file } = info;
			if (!isRemoving) {
				setFile(file);
				setFileList([file]);
			} else {
				isRemoving = false;
				setFile(null);
				setFileList([]);
			}
		},
		onDrop: () => {
			setFile(null);
			setFileList([]);
		},
		onRemove: () => {
			isRemoving = true;
		},
	};

	const handleSubmit = async (values: any) => {
		setLoading(true);
		const personalLevyFormData = new FormData();
		personalLevyFormData.append(
			'effectiveDate',
			formatToDateOnly(values.effectiveDate)
		);
		const monthAmountObject = values.entries.map((entry: Entry) => ({
			month: entry.month,
			amount: entry.amount,
		}));

		personalLevyFormData.append(
			'monthAmount',
			JSON.stringify(monthAmountObject)
		);

		personalLevyFormData.append(
			'notes',
			invalidText(values.notes) ? null : values.notes
		);
		personalLevyFormData.append('moduleName', 'PERSONAL_LEVY');
		if (file) {
			personalLevyFormData.append('file', file as Blob);
		}

		try {
			await personalLevyApi.createPersonalLevy(personalLevyFormData);
			toastText('Personal Levy details saved successfully', 'success');

			closeDrawerByAnimation(true);
			fetchPersonalLevyData();
			fetchAllPersonalLevyData();
		} catch (error: any) {
			console.error('Error submitting form:', error);

			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				toastText(error.response.data.message, 'error');
			} else {
				toastText('Failed to save Personal Levy details', 'error');
			}
		} finally {
			setLoading(false);
		}
	};

	const monthOptions = [
		{ value: 1, label: 'January' },
		{ value: 2, label: 'February' },
		{ value: 3, label: 'March' },
		{ value: 4, label: 'April' },
		{ value: 5, label: 'May' },
		{ value: 6, label: 'June' },
		{ value: 7, label: 'July' },
		{ value: 8, label: 'August' },
		{ value: 9, label: 'September' },
		{ value: 10, label: 'October' },
		{ value: 11, label: 'November' },
		{ value: 12, label: 'December' },
	];

	const dayOptions = Array.from({ length: 28 }, (_, i) => ({
		value: i + 1,
		label: (i + 1).toString(),
	}));

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					form={form}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 24 }}
					style={{ maxWidth: 600 }}
					onFinish={handleSubmit}
					initialValues={{ effectiveDate: date }}
					autoComplete="off"
					onValuesChange={onFormChange}
				>
					{' '}
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__napsa--label']}
						>
							{'Effective Date'}{' '}
							<span className="required-color">*</span>
						</label>
						<div>
							<Form.Item
								name="effectiveDate"
								rules={[
									{
										required: true,
										message: 'Please select a date',
									},
								]}
							>
								<DatePickerField
									onChange={handleDate}
									value={date}
									isError={false}
									disabled={false}
									placeholder="Select Date"
									required
									name="effectiveDate"
									disabledBeforeDates={dayjs().startOf('day')}
								/>
							</Form.Item>
						</div>
					</div>
					<Row gutter={16}>
						{entries.map((entry, index) => (
							<Col key={index} span={24}>
								<Row align="middle" gutter={16}>
									<Col span={10} className="input-column">
										<div
											className={
												styles[
													'side-drawer-form__inputs'
												]
											}
										>
											<label
												className={
													styles[
														'side-drawer-form__napsa--label'
													]
												}
											>
												Month{' '}
												<span className="required-color">
													*
												</span>
											</label>
											<Form.Item
												name={[
													'entries',
													index,
													'month',
												]}
												rules={[
													{
														required: true,
														message:
															'Please select a month',
													},
												]}
											>
												<Select
													size="large"
													value={entry.month}
													placeholder="Select Month"
													options={monthOptions}
													onChange={(value) =>
														handleChange(
															index,
															'month',
															value
														)
													}
												/>
											</Form.Item>
										</div>
									</Col>
									<Col span={10} className="input-column">
										<div
											className={
												styles[
													'side-drawer-form__inputs'
												]
											}
										>
											<label
												className={
													styles[
														'side-drawer-form__napsa--label'
													]
												}
											>
												Amount{' '}
												<span className="required-color">
													*
												</span>
											</label>
											<Form.Item
												name={[
													'entries',
													index,
													'amount',
												]}
												rules={[
													{
														required: true,
														message:
															'Please enter an amount',
													},
													{
														type: 'number',
														message:
															'Amount must be a number',
													},
												]}
											>
												<InputNumber
													size="large"
													value={entry.amount}
													placeholder="Enter Amount"
													suffix={'ZMW'}
													min={0}
													onChange={(value) =>
														handleChange(
															index,
															'amount',
															value
														)
													}
												/>
											</Form.Item>
										</div>
									</Col>
									<Col
										span={4}
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										{index > 0 ? (
											<MinusCircleOutlined
												onClick={() =>
													removeEntry(index)
												}
												className={styles['remove-btn']}
												style={{
													fontSize: '20px',
													color: 'black',
													marginLeft: '10px',
												}}
											/>
										) : null}
									</Col>
								</Row>
							</Col>
						))}
					</Row>
					<div>
						<p className={styles['addButton']} onClick={addNewRow}>
							{' '}
							<PlusOutlined /> Add Date & Month
						</p>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__napsa--label']}
						>
							{'Attachment'}
						</label>
						<Form.Item name="attachment" required>
							<Dragger {...propsUpload} className="test">
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-hint">
									Drag and drop a file or choose file from
									{/* Device Supported file formats. */}
									<br />
									{/* XLS, XLSX, CSV |{' '} */}
									<span className="color-purple">
										Maximum file size: 5MB
									</span>
								</p>
							</Dragger>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__napsa--label']}
						>
							{'Notes'}
						</label>
						<Form.Item name="notes" wrapperCol={{ span: 24 }}>
							<Input.TextArea
								className={styles['side-drawer-form--textarea']}
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>
		</>
	);
};

export default AddPersonalLevySideDrawer;
