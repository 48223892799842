/* eslint-disable react-hooks/exhaustive-deps */
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import React, { useContext, useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import Styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import GlobalReportTable from '../GlobalReportTable';
import GenerateP18Modal from './GenerateP18Modal';
import { reportApis } from 'Api/reports';
import { PermissionContext } from 'components/Global/AuthLayout';
import { Typography } from 'antd';

const P18ReportComponent = () => {
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [reportData, setReportData] = useState<any[]>([]);

	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Add_Payroll_Reports');

	const { Text, Link } = Typography;
	const buttons = [
		{
			text: 'Generate P18',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setModalOpen(true);
			},
			disabled: isLoading,
		},
	];

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const cancelModal = () => {
		setModalOpen(false);
	};

	const fetchReportDetails = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};
			setIsLoading(true);
			const p18Data = await reportApis.getP18Reports(query);
			setReportData(p18Data.data.data);
			setTotalRecords(0);
		} catch (err: any) {
			let message = 'Something went wrong in fetching P18 reports.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchReportDetails();
	}, [currentPage, sortField, sortOrder, pageSize]);

	return (
		<>
			<div className={Styles['container']}>
				<div className={Styles['P18-header']}>
					<p>P18</p>
					<span className={Styles['divider']}></span>
					<Text style={{ marginRight: '300px', color: '#444' }}>
						This report details employee tax submissions and
						reconciliations, typically required by the tax authority
						of Zambia (ZRA) for compliance purposes.
					</Text>
					{check && <Buttons buttons={buttons} />}
				</div>
				<GlobalReportTable
					ReportData={reportData}
					isLoading={isLoading}
					tableChangeHandler={tableChangeHandler}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
				/>
			</div>
			{modalOpen && (
				<>
					<GenerateP18Modal
						open={modalOpen}
						cancelGenerateModal={cancelModal}
						fetchReportDetails={fetchReportDetails}
					/>
				</>
			)}
		</>
	);
};

export default P18ReportComponent;
