import { Modal } from 'antd';
import { payrollApi } from 'Api/payroll';
import Buttons from 'components/Global/Buttons';
import React, { useEffect, useState } from 'react';
import { formatNumber, toastText } from 'utils/utils';
import styles from './index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { useSelector } from 'react-redux';
import Ellipse from 'components/Global/Ellipse';
import { FaFilePdf } from 'react-icons/fa';

type NotificationPayrollModalProps = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedPayroll: string;
	selectedPayrollLabel: string;
	approvalId: string;
	fetchApprovalRequest: () => void;
};

const NotificationPayrollModal = (props: NotificationPayrollModalProps) => {
	const {
		isOpen,
		handleCancel,
		selectedPayroll,
		selectedPayrollLabel,
		approvalId,
		fetchApprovalRequest,
	} = props;
	const [isLoading, setIsLoading] = useState(false);
	const [approveIsLoading, setApproveIsLoading] = useState(false);
	const [rejectIsLoading, setRejectIsLoading] = useState(false);
	const [payrollDetails, setPayrollDetails] = useState<any>(null);

	const { data } = useSelector((state: any) => state?.userProfile);

	const fetchPayrollDetails = async (id: string) => {
		try {
			setIsLoading(true);
			const response = await payrollApi.getPayrollDetails(id);
			setPayrollDetails(response.data.data);
		} catch (err) {
			toastText(
				'Something went wrong in getting payroll Details',
				'error'
			);
		} finally {
			setIsLoading(false);
		}
	};

	async function approvePayroll() {
		try {
			setApproveIsLoading(true);
			await payrollApi.approvePayroll({
				approvalId,
				payrollId: selectedPayroll,
			});
		} catch (error) {
			toastText('Something went wrong in approving payroll', 'error');
		} finally {
			setApproveIsLoading(false);
			toastText('Payroll approved successfully', 'success');
			handleCancel();
			fetchApprovalRequest();
		}
	}

	async function rejectPayroll() {
		try {
			setRejectIsLoading(true);
			await payrollApi.rejectPayroll({
				approvalId,
				payrollId: selectedPayroll,
			});
		} catch (error) {
			toastText('Something went wrong in rejecting payroll', 'error');
		} finally {
			setRejectIsLoading(false);
			toastText('Payroll rejected successfully', 'success');
			handleCancel();
			fetchApprovalRequest();
		}
	}
	const buttons = [
		{
			text: 'Approve',
			isSubmit: true,
			className: 'btn-blue',
			onclick: approvePayroll,
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			size: 'small',
			isLoading: approveIsLoading,
		},
		{
			text: 'Reject',
			isSubmit: true,
			className: 'secondary-button',
			onclick: rejectPayroll,
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			isLoading: rejectIsLoading,
		},
		{
			text: 'Cancel',
			isLoading: false,
			isSubmit: true,
			className: 'secondary-button',
			onclick: handleCancel,
			disabled: false,
		},
	];

	useEffect(() => {
		if (selectedPayroll) {
			fetchPayrollDetails(selectedPayroll);
		}
	}, [selectedPayroll]);
	return (
		<>
			<Modal
				open={isOpen}
				className="notificationPayrollModal"
				closable={false}
				onCancel={handleCancel}
				width={700}
				footer={() => (
					<div className={styles.footerButtons}>
						<Buttons buttons={buttons} />
					</div>
				)}
			>
				{isLoading ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '40vh',
						}}
					>
						<LoadingOutlined
							style={{ fontSize: '100px', color: '#584495' }}
						/>
					</div>
				) : payrollDetails ? (
					<div className={styles.container}>
						<h3>{selectedPayrollLabel}</h3>
						<div className={styles.infoSection}>
							<div className={styles.infoItem}>
								<p>Pay Period Name</p>
								<p>{payrollDetails?.payPeriod.name}</p>
							</div>
							<div className={styles.infoItem}>
								<p>Pay Year</p>
								<p>{payrollDetails?.payYear}</p>
							</div>
							<div className={styles.infoItem}>
								<p>Total Payable</p>
								<p>
									{formatNumber(
										Number(payrollDetails?.totalPayable),
										data?.CompanyCurrencies,	
										payrollDetails?.companyCurrency
											?.currency === 'USD'
											? true
											: false
									)}
								</p>
							</div>
							<div className={styles.infoItem}>
								<p>Currency</p>
								<p>
									{payrollDetails?.companyCurrency?.currency}
								</p>
							</div>
							<div className={styles.infoItem}>
								<p>Total Employees</p>
								<p>
									{payrollDetails?.PayrollEmployees?.length}
								</p>
							</div>
							<div className={styles.infoItem}>
								<p>Combine Pay Slip Of Employees</p>
								<p>
									<div>
										{payrollDetails?.paySlipName ? (
											<a
												href={
													payrollDetails.paySlipLink
												}
												target="_blank"
												rel="noopener noreferrer"
											>
												<FaFilePdf
													color="#d32f2f"
													size={20}
												/>{' '}
											</a>
										) : (
											'-'
										)}
									</div>
								</p>
							</div>
						</div>
					</div>
				) : (
					<div>No Payroll details available</div>
				)}
			</Modal>
		</>
	);
};

export default NotificationPayrollModal;
