import { Checkbox, Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Buttons from 'components/Global/Buttons';
import { useState } from 'react';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedNotification: any;
	handleRoleSubmit: any;
	roleData: any;
};

const RoleAssignModal = (props: Props) => {
	const {
		isOpen,
		handleCancel,
		selectedNotification,
		handleRoleSubmit,
		roleData,
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const [roles, setRoles] = useState<any>(roleData);

	const buttons = [
			{
			text: 'Cancel',
			isLoading: false,
			isSubmit: true,
			className: 'btn-blue',
			onclick: () => {
				handleCancel();
			},
			disabled: isLoading,
		},
		{
			text: 'Assign',
			isSubmit: true,
			className: 'btn-blue',
			onclick: () => {
				handleRoleSubmit(selectedNotification.id, roles);
			},
			disabled: isLoading,
			size: 'small',
			isLoading: false,
		},
	];

	const handleCheckNotification = (id: string, value: boolean) => {
		const _data = roles.map((item: any) => {
			if (item.id === id) {
				item.isChecked = value;
			}
			return item;
		});
		setRoles(_data);
	};

	return (
		<Modal
			open={isOpen}
			closable={false}
			style={{
				minWidth: '400px',
				zIndex: '99999',
			}}
			onCancel={handleCancel}
			footer={() => {
				return (
					<div style={{ marginTop: '2rem' }}>
						<Buttons buttons={buttons} />
					</div>
				);
			}}
		>
			<Table
				dataSource={roles}
				scroll={{ y: 'calc(100vh - 400px)' }}
				pagination={false}
				rowKey={(record) => record.employeeId}
				loading={isLoading}
				rowClassName={(record: any) => {
					return record.isTotal ? 'total-row' : '';
				}}
			>
				<Column
					title=""
					dataIndex="isChecked"
					key="isChecked"
					className="bg-white"
					width="70px"
					render={(record, data: any) => {
						return (
							<Checkbox
								checked={record}
								onChange={(e: any) =>
									handleCheckNotification(
										data.id,
										e.target.checked
									)
								}
							/>
						);
					}}
				/>
				<Column
					title="Role"
					dataIndex="roleName"
					key="roleName"
					className="bg-white"
				/>
			</Table>
		</Modal>
	);
};

export default RoleAssignModal;
